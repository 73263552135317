import { ErrorModal, useContextMenuMESF } from "@dexteel/mesf-core";
import { Grid, IconButton, Typography } from "@material-ui/core";
import {
  GetContextMenuItemsParams,
  GridApi,
  MenuItemDef,
  RowSelectedEvent,
} from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";

import { useGridDefinitions } from "../../../../../../controls/ag-grid/components/GridDefinitions";
import { CenteredLazyLoading } from "../../../../../../controls/LazyLoading";

import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import { Cassette } from "../../../../models/parts/Cassette";
import { deleteCassette } from "../../../../repositories/parts/CassetteRepository";
import { useGridStyles } from "../../../../styles/gridStyles";
import { DeletePart } from "../../DeletePart";
import { useCassettes } from "../CassettePage";
import { useCassetteOptionsFunctions } from "../hooks/useCassetteOptionsFunctions";
import { useTableData } from "../hooks/useTableData";
import { CassetteDetails } from "./CassetteDetails";

export const TableCassette = () => {
  const classes = useGridStyles();

  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [showModal, setShowModal] = useState<
    "create" | "update" | "delete" | ""
  >("");
  const [selectedRowData, setSelectedRowData] = useState<Cassette | null>(null);
  const [error, setError] = useState("");

  const { data: rows, isLoading } = useCassettes(undefined, (error) =>
    setError(error.message)
  );

  const { showContextMenu, registerConfig } = useContextMenuMESF();
  const { getMenuOptions } = useCassetteOptionsFunctions({
    setSelectedRowData,
    setShowModal,
  });
  const { columnDefs } = useTableData({
    setSelectedRowData,
    setShowModal,
    showContextMenu,
  });

  const { columnDefaults } = useGridDefinitions({
    OnEdit: (data: any) => {
      setShowModal("update");
      setSelectedRowData(data);
    },
  });

  const onRowSelected = (event: RowSelectedEvent) => {
    const cell = gridApi?.getFocusedCell();
    if (cell?.column.getColId() !== "-" && event.node.isSelected()) {
      setSelectedRowData(event.data);
      setShowModal("update");
    }
  };

  const getContextMenuItems = React.useCallback(
    (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
      const data = params.node?.data;
      showContextMenu(event as any, data, "cassetteContext");
      if (data) {
        params.api.deselectAll();
        params.node?.setSelected(true);
      }
      return [];
    },
    [rows]
  );

  const onModalHide = ({
    shouldUpdate,
    close,
    entityId,
  }: {
    shouldUpdate: boolean;
    close: boolean;
    entityId?: number;
  }) => {
    if (close) {
      setShowModal("");
      setSelectedRowData(null);
      if (gridApi) gridApi?.deselectAll();
      return;
    }
    if (entityId && shouldUpdate) {
      setTimeout(() => {
        const rowNode = gridApi?.getRowNode(String(entityId));

        const pageSize = gridApi?.paginationGetPageSize();
        const pageNumber = Math.floor(rowNode?.rowIndex ?? 0 / pageSize!);
        gridApi?.paginationGoToPage(pageNumber);

        rowNode?.setSelected(true);
        setSelectedRowData(rowNode?.data);
      }, 0);
    }
  };

  const handleNewCassette = () => {
    if (gridApi) gridApi.deselectAll();
    setShowModal("create");
    setSelectedRowData(null);
  };

  useEffect(() => {
    if (isLoading) {
      gridApi?.showLoadingOverlay();
    } else {
      gridApi?.hideOverlay();
    }
  }, [isLoading, rows, gridApi]);

  useEffect(() => {
    registerConfig({
      id: "cassetteContext",
      getOptions: getMenuOptions,
    });
  }, []);

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item md={12} xs={12} style={{ padding: "0 15px" }}>
          <Typography variant="h5" style={{ fontWeight: 600 }}>
            Cassettes
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          className={classes.root}
          style={{ width: "100%", padding: "0 10px" }}
          spacing={2}
        >
          <Grid item md={6} xs={12}>
            <div
              style={{ position: "relative", height: "90vh", width: "100%" }}
              className={`${classes.table} ag-theme-alpine`}
            >
              <AgGridReact<Cassette>
                rowData={rows || []}
                columnDefs={columnDefs}
                defaultColDef={columnDefaults}
                rowHeight={34}
                headerHeight={34}
                animateRows
                pagination
                rowSelection="single"
                paginationAutoPageSize
                getContextMenuItems={getContextMenuItems}
                onGridReady={(params) => setGridApi(params.api)}
                getRowId={(params) => String(params.data.PartId)}
                loadingOverlayComponent={CenteredLazyLoading}
                onRowSelected={onRowSelected}
              />
              <IconButton
                onClick={handleNewCassette}
                color="primary"
                style={{
                  position: "absolute",
                  padding: 0,
                  bottom: 19,
                  left: 25,
                }}
              >
                <PlaylistAddIcon style={{ width: 30, height: 30 }} />
              </IconButton>
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <div
              style={{ height: "90vh", width: "100%" }}
              className={`${classes.table} ag-theme-alpine`}
            >
              <CassetteDetails
                cassette={selectedRowData}
                onHide={onModalHide}
                modal={showModal}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <DeletePart
        show={showModal === "delete"}
        part={{
          Id: selectedRowData?.PartId!,
          Name: selectedRowData?.PartName!,
          Entity: "cassette",
        }}
        onHide={(update: boolean) => {
          onModalHide({ shouldUpdate: update, close: true });
          setShowModal(update ? "" : "update");
        }}
        deleteFunction={deleteCassette}
      />
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};
