import { Grid, TextField, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { ErrorModal } from "@dexteel/mesf-core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FooterDetailsButtons } from "../../../../../../controls/buttons/FooterDetailsButtons";
import { NotificationSnackBar } from "../../../../../../controls/snackbars/notification-snackbar";
import { Section } from "../../../../../../shared/models/Section";
import { setSectionsToPart } from "../../../../../../shared/repositories/SectionRepository";
import {
  ArborSelector,
  DonutSelector,
  LocationTreeSelector,
  RollPositionSelector,
  RollStatusSelector,
} from "../../../../../../shared/selectors";
import { SectionField } from "../../../../../../shared/selectors/SectionField";
import { useSectionsByPart } from "../../../../../../shared/selectors/SectionsOfPartSelector";
import {
  UNIVERSAL_ROLL_INITIAL_VALUES,
  UniversalRoll,
} from "../../../../models/parts/UniversalRoll";
import { Stand } from "../../../../models/Stand";
import { upsertUniversalRoll } from "../../../../repositories/parts/UniversalRollRepository";
import { setStandsToPart } from "../../../../repositories/StandRepository";
import { PartCommentsField } from "../../../../shared/components/PartCommentsField";
import { useArbors } from "../../arbors/ArborPage";
import { useDonuts } from "../../donuts/DonutPage";
import { StandGrid, useStandsByPart } from "../../StandGrid";

type Props = {
  roll: UniversalRoll | null;
  modal: "create" | "update" | "delete" | "";
  onHide: ({
    shouldUpdate,
    close,
    entityId,
  }: {
    shouldUpdate: boolean;
    close: boolean;
    entityId?: number;
  }) => void;
};

export const UniversalRollDetails = ({ roll, modal, onHide }: Props) => {
  const [message, setNotificationMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  const [standsSelected, setStandsSelected] = useState<Stand[]>([]);
  const [sectionsSelected, setSectionsSelected] = useState<Section[]>([]);
  const [forceAssembly, setForceAssembly] = useState(false);

  const succesfullyMessage = `The roll was ${modal}d succesfully`;

  const {
    control,
    reset,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { dirtyFields },
  } = useForm<UniversalRoll>({
    defaultValues: UNIVERSAL_ROLL_INITIAL_VALUES,
    mode: "onChange",
  });

  const { data: arbors } = useArbors({
    partId: undefined,
    onError: (error) => setError(error.message),
  });
  const { data: donuts } = useDonuts({
    partId: undefined,
    onError: (error) => setError(error.message),
  });

  const { data: sectionsOfPart } = useSectionsByPart({
    partId: roll?.PartId ?? 0,
    onError: (error) => setError(error.message),
    onSuccess: (data) => {
      if (data && data.length > 0) {
        setSectionsSelected([...data]);
      }
    },
  });
  const sectionsJson = JSON.stringify(
    sectionsSelected.map((section: Section) => ({
      ...section,
      PartId: roll?.PartId,
      PartName: roll?.PartName,
    }))
  );
  const oldSectionsJson = JSON.stringify(sectionsOfPart);

  const { data: standsOfPart } = useStandsByPart({
    partId: roll?.PartId!,
    onError: (error) => setError(error.message),
    onSuccess: (data) => {
      if (data && data.length > 0) {
        setStandsSelected([...data]);
      }
    },
  });
  const standsJson = JSON.stringify(
    standsSelected.map((stand: Stand) => ({
      ...stand,
      PartId: roll?.PartId,
      PartName: roll?.PartName,
    }))
  );
  const oldStandsJson = JSON.stringify(standsOfPart);

  const isFormDirty =
    !(Object.keys(dirtyFields).length === 0) ||
    standsJson !== oldStandsJson ||
    sectionsJson !== oldSectionsJson;

  const queryClient = useQueryClient();
  const upsertMutation = useMutation(
    ({
      data,
      onForceAssembly,
    }: {
      data: UniversalRoll;
      onForceAssembly: boolean;
    }) => upsertUniversalRoll(data, onForceAssembly),
    {
      onSuccess: async (id: number) => {
        if (id && standsSelected) {
          const resp = await setStandsToPart(id, standsSelected);
          if (!resp.ok) setError(resp.message);
        }
        if (id && sectionsSelected) {
          const resp = await setSectionsToPart(id, sectionsSelected);
          if (!resp.ok) setError(resp.message);
        }

        setNotificationMessage(succesfullyMessage);
        await queryClient.invalidateQueries(["universalRolls"]);
        onHide({ shouldUpdate: true, close: false, entityId: id });
      },
      onError: (error: Error) => {
        setError(error.message);
      },
      onSettled: () => setIsSubmitLoading(false),
    }
  );

  const onSubmit: SubmitHandler<UniversalRoll> = async (data) => {
    setIsSubmitLoading(true);
    if (modal !== "delete") {
      upsertMutation.mutate({ data, onForceAssembly: forceAssembly });
    }
  };

  useEffect(() => {
    if (modal !== "") {
      reset(roll || UNIVERSAL_ROLL_INITIAL_VALUES);
      setIsSubmitLoading(false);
      setStandsSelected([]);
      setSectionsSelected([]);
    }
  }, [modal, roll]);

  return (
    <>
      {roll === null && modal !== "create" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Typography>Select a roll or create a new one</Typography>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <Controller
                name="ArborPartId"
                control={control}
                rules={{ required: "Arbor is required" }}
                render={({ field }) => (
                  <ArborSelector
                    label="Arbor"
                    value={field.value}
                    onChange={(value: number | null) => {
                      field.onChange(value);
                      const arbor = arbors?.find(
                        (arbor) => value === arbor.PartId
                      );
                      if (arbor && arbor.StandsId != null) {
                        const standsArray = arbor?.StandsId.split(",").map(
                          (stand) => {
                            const standId = parseInt(stand.trim());
                            return {
                              StandId: standId,
                              StandName: ``,
                              PartId: roll?.PartId,
                              PartName: roll?.PartName,
                            } as Stand;
                          }
                        );
                        setStandsSelected((prevStands) => {
                          const combinedStands = [
                            ...prevStands,
                            ...standsArray,
                          ];
                          return [...new Set(combinedStands)];
                        });
                      }
                      setValue("PartName", `UR - ${arbor?.PartName ?? ""}`);
                    }}
                    disabled={modal === "delete"}
                    onForceAssembly={setForceAssembly}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SectionField
                label="Sections"
                onSelectionChanged={setSectionsSelected}
                sectionsOfPart={sectionsSelected}
                disabled={modal === "delete"}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Controller
                name="PartName"
                control={control}
                rules={{ required: "Name is required" }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    label="Name"
                    variant="outlined"
                    {...field}
                    size="small"
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                    disabled
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="DonutPartId"
                control={control}
                rules={{ required: "Donut is required" }}
                render={({ field }) => (
                  <DonutSelector
                    label="Donut"
                    value={field.value}
                    onChange={(value: number | null) => {
                      field.onChange(value);
                      const donut = donuts?.find(
                        (donut) => value === donut.PartId
                      );
                      if (donut) {
                        if (donut.StandsId != null) {
                          const standsArray = donut.StandsId.split(",").map(
                            (stand) => {
                              const standId = parseInt(stand.trim());
                              return {
                                StandId: standId,
                                StandName: ``,
                                PartId: roll?.PartId,
                                PartName: roll?.PartName,
                              } as Stand;
                            }
                          );
                          setStandsSelected((prevStands) => {
                            const combinedStands = [
                              ...prevStands,
                              ...standsArray,
                            ];
                            return [...new Set(combinedStands)];
                          });
                        }

                        if (donut.SectionsId != null) {
                          const sectionsArray = (() => {
                            const sectionIds = donut.SectionsId.split(",");
                            const sectionNames = donut.Sections.split(",");

                            return sectionIds.map((sectionId, index) => {
                              const id = parseInt(sectionId.trim());
                              const name = sectionNames[index]?.trim() || "";

                              return {
                                SectionId: id,
                                SectionName: name,
                                SectionFamilyName: "",
                                IsActive: true,
                              } as Section;
                            });
                          })();
                          setSectionsSelected((prevSections) => {
                            const combinedSections = [
                              ...prevSections,
                              ...sectionsArray,
                            ];
                            return [...new Set(combinedSections)];
                          });
                        }
                      }
                    }}
                    disabled={modal === "delete"}
                    onForceAssembly={setForceAssembly}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="RollPosition"
                control={control}
                rules={{ required: "Position is required" }}
                render={({ field, fieldState: { error } }) => (
                  <RollPositionSelector
                    label="Position"
                    value={field.value}
                    onChange={field.onChange}
                    fieldError={error}
                    disabled={modal === "delete"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ marginTop: -8 }}>
              <Controller
                name="LocationId"
                control={control}
                render={({ field }) => (
                  <LocationTreeSelector
                    label="Location"
                    value={field.value}
                    onChange={field.onChange}
                    disabled={modal === "delete"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="RollStatusCode"
                control={control}
                rules={{ required: "Status is required" }}
                render={({ field, fieldState: { error } }) => (
                  <RollStatusSelector
                    label="Status"
                    value={field.value}
                    onChange={field.onChange}
                    fieldError={error}
                    disabled={modal === "delete"}
                  />
                )}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Controller
                name="RFIDTag"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Tag"
                    variant="outlined"
                    {...field}
                    size="small"
                    fullWidth
                    disabled
                  />
                )}
              />
            </Grid>
            <StandGrid
              onSelectionChanged={setStandsSelected}
              disabled={modal === "delete"}
              standsOfPart={standsSelected}
            />
            <Grid item md={12} sm={12}>
              <Controller
                name="Comments"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <PartCommentsField
                    value={field.value}
                    onChange={field.onChange}
                    fieldError={error}
                    getValues={getValues}
                    disabled={modal === "delete"}
                  />
                )}
              />
            </Grid>
            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              style={{ margin: 15 }}
            >
              <FooterDetailsButtons
                isSubmitLoading={isSubmitLoading}
                disabled={!isFormDirty}
                onHide={() => onHide({ shouldUpdate: false, close: true })}
              />
            </Grid>
          </Grid>
        </form>
      )}
      <NotificationSnackBar
        message={message}
        onHide={() => setNotificationMessage("")}
      />
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};
