import { ErrorModal } from "@dexteel/mesf-core";
import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { upsertEdgerRollChannel } from "src/pages/configuration/repositories/parts/EdgerRollRepository";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  chip: {
    height: 18,
    width: 18,
    borderRadius: 4,
    cursor: "pointer",
  },
}));

export const StatusCheckbox = ({
  status,
  channelNumber,
  partId,
}: {
  status?: string;
  channelNumber?: number;
  partId: number;
}) => {
  const classes = useStyles();

  const [error, setError] = useState("");

  const isReady = status === "Ready";
  const backgroundColor = isReady ? "green" : "red";

  const queryClient = useQueryClient();
  const upsertMutation = useMutation(
    ({
      status,
      channelNumber,
      partId,
    }: {
      status?: string;
      channelNumber?: number;
      partId: number;
    }) =>
      upsertEdgerRollChannel({
        status: status?.charAt(0),
        channelNumber,
        partId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["channels"]);
      },
      onError: (error: Error) => {
        setError(error.message);
      },
    }
  );

  const handleClick = () =>
    upsertMutation.mutate({
      status: isReady ? "D" : "R",
      channelNumber,
      partId,
    });

  return (
    <>
      <div className={classes.root}>
        <Chip
          className={classes.chip}
          style={{ backgroundColor }}
          onClick={handleClick}
          size="small"
          label={" "}
          variant="default"
        />
      </div>
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};
