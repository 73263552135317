import { ErrorModal } from "@dexteel/mesf-core";
import { Grid, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import {
  deleteEdgerRollChannel,
  upsertEdgerRollChannel,
} from "src/pages/configuration/repositories/parts/EdgerRollRepository";

interface Props {
  partId: number;
  disabled: { upsert: boolean; delete: boolean };
}

export const FooterChannelsButtons = ({ partId, disabled }: Props) => {
  const [error, setError] = useState<string>("");
  debugger;
  const queryClient = useQueryClient();
  const upsertMutation = useMutation(
    ({ partId }: { partId: number }) =>
      upsertEdgerRollChannel({
        partId,
      }),
    {
      onSuccess: (id: number) => {
        queryClient.invalidateQueries(["channels"]);
      },
      onError: (error: Error) => {
        setError(error.message);
      },
    }
  );
  const deleteMutation = useMutation(
    ({ partId }: { partId: number }) =>
      deleteEdgerRollChannel({
        partId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["channels"]);
      },
      onError: (error: Error) => {
        setError(error.message);
      },
    }
  );

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item md={6}>
          <IconButton
            color="primary"
            size="small"
            style={{ marginRight: 10 }}
            onClick={() => upsertMutation.mutate({ partId: partId })}
            disabled={disabled.upsert}
          >
            <PlaylistAddIcon />
          </IconButton>
        </Grid>
        <Grid
          item
          md={6}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <IconButton
            color="secondary"
            size="small"
            onClick={() => deleteMutation.mutate({ partId: partId })}
            disabled={disabled.delete}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};
