import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { useQuery } from "@tanstack/react-query";
import { Donut } from "../../../models/parts/Donut";
import { getDonuts } from "../../../repositories/parts/DonutRepository";
import { usePageStyles } from "../../../styles/pageStyles";
import { TableDonut } from "./components/TableDonut";

type useDonutsProps = {
  partId?: number;
  onError?: (error: Error) => void;
};

export const useDonuts = ({ partId, onError }: useDonutsProps) => {
  return useQuery<Donut[], Error>({
    queryKey: ["donuts", partId],
    queryFn: () => getDonuts(partId),
    onError: onError,
  });
};

export const DonutPage = () => {
  const classes = usePageStyles();

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={12} xs={12} style={{ margin: 0 }}>
          <Paper elevation={1} className={classes.paper}>
            <TableDonut />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
