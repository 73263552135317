import { Grid, TextField, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { ErrorModal } from "@dexteel/mesf-core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FooterDetailsButtons } from "../../../../../../controls/buttons/FooterDetailsButtons";
import { NotificationSnackBar } from "../../../../../../controls/snackbars/notification-snackbar";
import {
  CARRIER_INITIAL_VALUES,
  Carrier,
} from "../../../../models/parts/Carrier";
import { Stand } from "../../../../models/Stand";
import { upsertCarrier } from "../../../../repositories/parts/CarrierRepository";
import { setStandsToPart } from "../../../../repositories/StandRepository";
import { PartCommentsField } from "../../../../shared/components/PartCommentsField";
import { PartFields } from "../../PartFields";
import { StandGrid, useStandsByPart } from "../../StandGrid";

type Props = {
  carrier: Carrier | null;
  modal: "create" | "update" | "delete" | "";
  onHide: ({
    shouldUpdate,
    close,
    entityId,
  }: {
    shouldUpdate: boolean;
    close: boolean;
    entityId?: number;
  }) => void;
};

export const assemblyStatusCodes = [
  { Code: "E", Name: "Empty" },
  { Code: "A", Name: "Assembly In Progress" },
  { Code: "R", Name: "Ready" },
  { Code: "S", Name: "Disassembled In Progress" },
  { Code: "I", Name: "Inhibited" },
];

export const CarrierDetails = ({ carrier, modal, onHide }: Props) => {
  const [message, setNotificationMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  const [standsSelected, setStandsSelected] = useState<Stand[]>([]);

  const succesfullyMessage = `The carrier was ${modal}d succesfully`;

  const {
    control,
    reset,
    handleSubmit,
    getValues,
    formState: { dirtyFields },
  } = useForm<Carrier>({
    defaultValues: CARRIER_INITIAL_VALUES,
    mode: "onChange",
  });

  const { data: standsOfPart } = useStandsByPart({
    partId: carrier?.PartId!,
    onError: (error) => setError(error.message),
    onSuccess: (data) => {
      if (data && data.length > 0) {
        setStandsSelected([...data]);
      }
    },
  });
  const standsJson = JSON.stringify(
    standsSelected.map((stand: Stand) => ({
      ...stand,
      PartId: carrier?.PartId,
      PartName: carrier?.PartName,
    }))
  );
  const oldStandsJson = JSON.stringify(standsOfPart);
  const isFormDirty =
    !(Object.keys(dirtyFields).length === 0) || standsJson !== oldStandsJson;

  const queryClient = useQueryClient();
  const upsertMutation = useMutation(upsertCarrier, {
    onSuccess: async (id: number) => {
      if (id) {
        const resp = await setStandsToPart(id, standsSelected);
        if (!resp.ok) setError(resp.message);
      }

      setNotificationMessage(succesfullyMessage);
      await queryClient.invalidateQueries(["carriers"]);
      onHide({ shouldUpdate: true, close: false, entityId: id });
    },
    onError: (error: Error) => {
      setError(error.message);
    },
    onSettled: () => setIsSubmitLoading(false),
  });

  const onSubmit: SubmitHandler<Carrier> = async (data) => {
    setIsSubmitLoading(true);
    if (modal !== "delete") upsertMutation.mutate(data);
  };

  useEffect(() => {
    if (modal !== "") {
      reset(carrier || CARRIER_INITIAL_VALUES);
      setIsSubmitLoading(false);
      setStandsSelected([]);
    }
  }, [modal, carrier]);

  return (
    <>
      {carrier === null && modal !== "create" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Typography>Select a carrier or create a new one</Typography>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <PartFields<Carrier>
              control={control}
              locationSizeMd={6}
              disabled={modal === "delete"}
            />
            <Grid item xs={12} md={6}>
              <Controller
                name="AssemblyStatusCode"
                control={control}
                render={({ field }) => (
                  <TextField
                    value={
                      assemblyStatusCodes.find(
                        (code) => code.Code === field.value
                      )?.Name
                    }
                    label="Assembly Status"
                    variant="outlined"
                    fullWidth
                    size="small"
                    disabled
                  />
                )}
              />
            </Grid>
            <StandGrid
              onSelectionChanged={setStandsSelected}
              disabled={modal === "delete"}
              standsOfPart={standsSelected}
            />
            <Grid item xs={12} md={12}>
              <Controller
                name="Comments"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <PartCommentsField
                    value={field.value}
                    onChange={field.onChange}
                    fieldError={error}
                    getValues={getValues}
                    disabled={modal === "delete"}
                  />
                )}
              />
            </Grid>
            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              style={{ margin: 15 }}
            >
              <FooterDetailsButtons
                isSubmitLoading={isSubmitLoading}
                disabled={!isFormDirty}
                onHide={() => onHide({ shouldUpdate: false, close: true })}
              />
            </Grid>
          </Grid>
        </form>
      )}
      <NotificationSnackBar
        message={message}
        onHide={() => setNotificationMessage("")}
      />
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};
