import { ErrorModal, getMomentTz, MesfModal } from "@dexteel/mesf-core";
import { FormControl, FormHelperText, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { FooterModalButtons } from "../../../../../../controls/buttons/FooterModalButtons";
import { CenteredLazyLoading } from "../../../../../../controls/LazyLoading";
import { NotificationSnackBar } from "../../../../../../controls/snackbars/notification-snackbar";

import { DatePicker } from "@mui/x-date-pickers";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  CASSETTE_CHOCK_INITIAL_VALUES,
  CassetteChock,
} from "../../../../models/parts/CassetteChock";
import {
  deleteCassetteChock,
  upsertCassetteChock,
} from "../../../../repositories/parts/CassetteChockRepository";
import { PartCommentsField } from "../../../../shared/components/PartCommentsField";
import { PartFields } from "../../PartFields";
import { useCassetteChocks } from "../CassetteChockPage";

const moment = getMomentTz();

type Props = {
  CassetteChockId: number | null;
  modal: "create" | "update" | "delete" | "";
  onHide: (shouldUpdate: boolean) => void;
};
export const UpsertDeleteModal = ({
  CassetteChockId,
  modal,
  onHide,
}: Props) => {
  const [title, setTitle] = useState<string>("CREATE");
  const [message, setNotificationMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  const succesfullyMessage = `The cassette chock was ${modal}d succesfully`;

  const { data: rows, isLoading } = useCassetteChocks(
    CassetteChockId || undefined,
    (error) => setError(error.message)
  );

  const cassette = rows ? rows[0] : null;

  const {
    control,
    reset,
    handleSubmit,
    getValues,
    formState: { dirtyFields },
  } = useForm<CassetteChock>({
    defaultValues: CASSETTE_CHOCK_INITIAL_VALUES,
    mode: "onChange",
  });

  const isFormDirty = !(Object.keys(dirtyFields).length === 0);

  const queryClient = useQueryClient();
  const deleteMutation = useMutation(deleteCassetteChock, {
    onSuccess: () => {
      queryClient.invalidateQueries(["cassetteChocks"]);
      setNotificationMessage(succesfullyMessage);
      onHide(true);
    },
    onError: (error: Error) => {
      setError(error.message);
    },
    onSettled: () => setIsSubmitLoading(false),
  });
  const upsertMutation = useMutation(upsertCassetteChock, {
    onSuccess: () => {
      queryClient.invalidateQueries(["cassetteChocks"]);
      setNotificationMessage(succesfullyMessage);
      onHide(true);
    },
    onError: (error: Error) => {
      setError(error.message);
    },
    onSettled: () => setIsSubmitLoading(false),
  });

  const onSubmit: SubmitHandler<CassetteChock> = async (data) => {
    setIsSubmitLoading(true);
    if (modal === "delete") {
      deleteMutation.mutate(data.PartId);
    } else {
      upsertMutation.mutate(data);
    }
  };

  const validateNotInFuture = (description: string, value: Date | null) => {
    if (value && value > new Date()) {
      return `${description} time cannot be in the future.`;
    }
    return true;
  };

  useEffect(() => {
    if (modal !== "") {
      setIsSubmitLoading(false);
      reset(modal !== "create" ? cassette! : CASSETTE_CHOCK_INITIAL_VALUES);
      setTitle(
        modal === "create" ? "CREATE" : modal === "update" ? "EDIT" : "DELETE"
      );
    }
  }, [modal, cassette]);

  return (
    <>
      <MesfModal
        title={`${title} CASSETTE CHOCK`}
        open={modal !== ""}
        handleClose={() => onHide(false)}
        maxWidth="sm"
        id="cassette-chock-modal"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <MesfModal.Content dividers style={{ padding: "10px 30px" }}>
            {isLoading && <CenteredLazyLoading />}
            {!isLoading && (
              <>
                <Grid container spacing={1}>
                  <PartFields<CassetteChock>
                    control={control}
                    disabled={modal === "delete"}
                  />
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="RebuildTimestamp"
                      control={control}
                      shouldUnregister
                      rules={{
                        validate: {
                          notInFuture: (value) =>
                            validateNotInFuture("Rebuild", value),
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl fullWidth error={!!error}>
                          <DatePicker
                            disabled={modal === "delete"}
                            label="Rebuild Date"
                            format="MM/DD/YYYY"
                            views={["year", "month", "day"]}
                            value={field.value ? moment(field.value) : null}
                            onChange={field.onChange}
                            slotProps={{
                              textField: {
                                size: "small",
                                error: !!error,
                              },
                            }}
                          />
                          {error && (
                            <FormHelperText>{error.message}</FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="BearingChangeTimestamp"
                      control={control}
                      shouldUnregister
                      rules={{
                        validate: {
                          notInFuture: (value) =>
                            validateNotInFuture("Bearing Change", value),
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl fullWidth error={!!error}>
                          <DatePicker
                            disabled={modal === "delete"}
                            label="Bearing Change Date"
                            format="MM/DD/YYYY"
                            views={["year", "month", "day"]}
                            value={field.value ? moment(field.value) : null}
                            onChange={field.onChange}
                            slotProps={{
                              textField: {
                                size: "small",
                                error: !!error,
                              },
                            }}
                          />
                          {error && (
                            <FormHelperText>{error.message}</FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Controller
                      name="Comments"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <PartCommentsField
                          value={field.value}
                          onChange={field.onChange}
                          fieldError={error}
                          getValues={getValues}
                          disabled={modal === "delete"}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </MesfModal.Content>
          <MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
            <FooterModalButtons
              isSubmitLoading={isSubmitLoading}
              disabled={!isFormDirty && modal !== "delete"}
              onHide={onHide}
              buttonLabel={modal === "delete" ? "Delete" : "Save"}
              buttonColor={modal === "delete" ? "secondary" : "primary"}
            />
          </MesfModal.Actions>
        </form>
      </MesfModal>
      <NotificationSnackBar
        message={message}
        onHide={() => setNotificationMessage("")}
      />
      <ErrorModal
        error={error}
        onHide={() => setError("")}
        title="Error In Cassette Chock"
      />
    </>
  );
};
