import { Part, PART_INITIAL_VALUES } from "../../configuration/models/parts/Part";

export interface Carrier extends Part {
	RollsetId: number | null;
	TopRollId: number | null;
	TopRollName: string | null;
	BottomRollId: number | null;
	BottomRollName: string | null;
	UMChockId: number | null;
	UMChockName: string | null;
	LMChockId: number | null;
	LMChockName: string | null;
	UOChockId: number | null;
	UOChockName: string | null;
	LOChockId: number | null;
	LOChockName: string | null;
	EntryGuideId: number | null;
	EntryGuideName: string | null;
	ExitGuideId: number | null;
	ExitGuideName: string | null;
	CassetteId: number | null;
	CassetteName: string | null;
	AssemblyStatusCode: string;
	Bait: number | null;
	WeighUp: number | null;
	ScheduledSectionId: number | null;
	SectionName: string | null;
	ScheduledLocationId: number | null;
	ScheduledLocationName: string | null;
	IsActive: boolean | null;
}

export const CARRIER_INITIAL_VALUES: Carrier = {
	...PART_INITIAL_VALUES,
	RollsetId: null,
	TopRollId: null,
	TopRollName: '',
	BottomRollId: null,
	BottomRollName: '',
	UMChockId: null,
	UMChockName: '',
	LMChockId: null,
	LMChockName: '',
	UOChockId: null,
	UOChockName: '',
	LOChockId: null,
	LOChockName: '',
	EntryGuideId: null,
	EntryGuideName: '',
	ExitGuideId: null,
	ExitGuideName: '',
	CassetteId: null,
	CassetteName: null,
	AssemblyStatusCode: 'D',
	Bait: null,
	WeighUp: null,
	ScheduledSectionId: null,
	SectionName: '',
	ScheduledLocationId: null,
	ScheduledLocationName: '',
	IsActive: true
};