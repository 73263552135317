import { Part, PART_INITIAL_VALUES } from "./Part";

export interface UniversalRoll extends Part {
	RollPosition: string | null;
	RollStatusCode: string | null;
	RollTypeCode: string | null;
	DonutDiameter: number | null;
	DonutNewDiameter: number | null;
	DonutScrapDiameter: number | null;
	DonutPartId: number | null;
	DonutPartName: string | null;
	ArborPartId: number | null;
	ArborPartName: string | null;
	PercentUsage: number | null;
	CampaignTons: number | null;
	TotalTons: number | null;
	IsActive: boolean | null;
}

export const UNIVERSAL_ROLL_INITIAL_VALUES: UniversalRoll = {
	...PART_INITIAL_VALUES,
	RollPosition: 'B',
	RollStatusCode: 'R',
	RollTypeCode: 'E',
	DonutDiameter: null,
	DonutNewDiameter: null,
	DonutScrapDiameter: null,
	DonutPartId: null,
	DonutPartName: '',
	ArborPartId: null,
	ArborPartName: "",
	PercentUsage: null,
	CampaignTons: null,
	TotalTons: null,
	IsActive: true
};