import { Grid, TextField, Typography } from "@material-ui/core";
import { ChangeEvent, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { ErrorModal } from "@dexteel/mesf-core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FooterDetailsButtons } from "../../../../../../controls/buttons/FooterDetailsButtons";
import { NotificationSnackBar } from "../../../../../../controls/snackbars/notification-snackbar";
import { Section } from "../../../../../../shared/models/Section";
import { setSectionsToPart } from "../../../../../../shared/repositories/SectionRepository";
import { DonutStatusSelector } from "../../../../../../shared/selectors";
import { SectionField } from "../../../../../../shared/selectors/SectionField";
import { useSectionsByPart } from "../../../../../../shared/selectors/SectionsOfPartSelector";
import { Donut, DONUT_INITIAL_VALUES } from "../../../../models/parts/Donut";
import { Stand } from "../../../../models/Stand";
import { upsertDonut } from "../../../../repositories/parts/DonutRepository";
import { setStandsToPart } from "../../../../repositories/StandRepository";
import { PartCommentsField } from "../../../../shared/components/PartCommentsField";
import { PartFields } from "../../PartFields";
import { StandGrid, useStandsByPart } from "../../StandGrid";

type Props = {
  donut: Donut | null;
  modal: "create" | "update" | "delete" | "";
  onHide: ({
    shouldUpdate,
    close,
    entityId,
  }: {
    shouldUpdate: boolean;
    close: boolean;
    entityId?: number;
  }) => void;
};

export const DonutDetails = ({ donut, modal, onHide }: Props) => {
  const [message, setNotificationMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  const [standsSelected, setStandsSelected] = useState<Stand[]>([]);
  const [sectionsSelected, setSectionsSelected] = useState<Section[]>([]);

  const succesfullyMessage = `The donut was ${modal}d succesfully`;

  const {
    control,
    reset,
    handleSubmit,
    getValues,
    formState: { dirtyFields },
  } = useForm<Donut>({
    defaultValues: DONUT_INITIAL_VALUES,
    mode: "onChange",
  });

  const { data: sectionsOfPart } = useSectionsByPart({
    partId: donut?.PartId ?? 0,
    onError: (error) => setError(error.message),
    onSuccess: (data) => {
      if (data && data.length > 0) {
        setSectionsSelected([...data]);
      }
    },
  });
  const sectionsJson = JSON.stringify(
    sectionsSelected.map((section: Section) => ({
      ...section,
      PartId: donut?.PartId,
      PartName: donut?.PartName,
    }))
  );
  const oldSectionsJson = JSON.stringify(sectionsOfPart);

  const { data: standsOfPart } = useStandsByPart({
    partId: donut?.PartId!,
    onError: (error) => setError(error.message),
    onSuccess: (data) => {
      if (data && data.length > 0) {
        setStandsSelected([...data]);
      }
    },
  });
  const standsJson = JSON.stringify(
    standsSelected.map((stand: Stand) => ({
      ...stand,
      PartId: donut?.PartId,
      PartName: donut?.PartName,
    }))
  );
  const oldStandsJson = JSON.stringify(standsOfPart);

  const isFormDirty =
    !(Object.keys(dirtyFields).length === 0) ||
    standsJson !== oldStandsJson ||
    sectionsJson !== oldSectionsJson;

  const validateDiameters = (
    value: number | null,
    field: "Diameter" | "NewDiameter" | "ScrapDiameter"
  ) => {
    const currentDiameter = getValues("Diameter") || 0;

    if (value === null || value === undefined) return true;

    switch (field) {
      case "NewDiameter":
        return (
          value >= currentDiameter ||
          "Must be greater than or equal to the current diameter"
        );
      case "ScrapDiameter":
        return (
          value < currentDiameter || "Must be less than the current diameter"
        );
      default:
        return true;
    }
  };

  const onChangeRealType = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (value: string) => void
  ) => {
    const value = e.target.value.replace(",", ".");
    if (value === "" || /^(\d*\.?\d{0,3}|\d+\.)$/.test(value)) {
      onChange(value);
    }
  };
  const onBlurRealType = (onChange: (value: string) => void, value: string) => {
    const numValue = parseFloat(value);
    if (!isNaN(numValue)) {
      onChange(numValue.toFixed(3));
    } else if (value === "") {
      onChange("");
    }
  };

  const queryClient = useQueryClient();
  const upsertMutation = useMutation(upsertDonut, {
    onSuccess: async (id: number) => {
      if (id) {
        const resp = await setStandsToPart(id, standsSelected);
        if (!resp.ok) setError(resp.message);
      }
      if (id) {
        const resp = await setSectionsToPart(id, sectionsSelected);
        if (!resp.ok) setError(resp.message);
      }

      setNotificationMessage(succesfullyMessage);
      await queryClient.invalidateQueries(["donuts"]);
      onHide({ shouldUpdate: true, close: false, entityId: id });
    },
    onError: (error: Error) => {
      setError(error.message);
    },
    onSettled: () => setIsSubmitLoading(false),
  });

  const onSubmit: SubmitHandler<Donut> = async (data) => {
    setIsSubmitLoading(true);
    if (modal !== "delete") {
      upsertMutation.mutate(data);
    }
    setIsSubmitLoading(false);
  };

  useEffect(() => {
    if (modal !== "") {
      reset(donut || DONUT_INITIAL_VALUES);
      setIsSubmitLoading(false);
      setStandsSelected([]);
      setSectionsSelected([]);
    }
  }, [modal, donut]);

  return (
    <>
      {donut === null && modal !== "create" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Typography>Select a donut or create a new one</Typography>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <PartFields<Donut>
              control={control}
              locationSizeMd={6}
              disabled={modal === "delete"}
              hideTag
            />

            <Grid item xs={12} md={6}>
              <SectionField
                label="Sections"
                onSelectionChanged={setSectionsSelected}
                sectionsOfPart={sectionsSelected}
                disabled={modal === "delete"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="DonutStatusCode"
                rules={{ required: "Status is required" }}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <DonutStatusSelector
                    label="Status"
                    value={field.value}
                    onChange={field.onChange}
                    fieldError={error}
                    disabled={modal === "delete"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="Diameter"
                rules={{
                  validate: {
                    crossValidate: (value) =>
                      validateDiameters(value, "Diameter"),
                  },
                  deps: ["NewDiameter", "ScrapDiameter"],
                }}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    label="Current Diameter"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    size="small"
                    autoComplete="off"
                    value={value ?? ""}
                    onChange={(e) => onChangeRealType(e, onChange)}
                    onBlur={() => onBlurRealType(onChange, String(value))}
                    disabled={modal === "delete"}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="NewDiameter"
                rules={{
                  validate: {
                    crossValidate: (value) =>
                      validateDiameters(value, "NewDiameter"),
                  },
                  deps: ["Diameter", "ScrapDiameter"],
                }}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    label="New Diameter"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    size="small"
                    autoComplete="off"
                    value={value ?? ""}
                    onChange={(e) => onChangeRealType(e, onChange)}
                    onBlur={() => onBlurRealType(onChange, String(value))}
                    disabled={modal === "delete"}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="ScrapDiameter"
                rules={{
                  validate: {
                    crossValidate: (value) =>
                      validateDiameters(value, "ScrapDiameter"),
                  },
                  deps: ["Diameter", "NewDiameter"],
                }}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    label="Scrap Diameter"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    size="small"
                    autoComplete="off"
                    value={value ?? ""}
                    onChange={(e) => onChangeRealType(e, onChange)}
                    onBlur={() => onBlurRealType(onChange, String(value))}
                    disabled={modal === "delete"}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
            <StandGrid
              onSelectionChanged={setStandsSelected}
              disabled={modal === "delete"}
              standsOfPart={standsSelected}
            />
            <Grid item md={12} sm={12}>
              <Controller
                name="Comments"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <PartCommentsField
                    value={field.value}
                    onChange={field.onChange}
                    fieldError={error}
                    getValues={getValues}
                    disabled={modal === "delete"}
                  />
                )}
              />
            </Grid>
            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              style={{ margin: 15 }}
            >
              <FooterDetailsButtons
                isSubmitLoading={isSubmitLoading}
                disabled={!isFormDirty}
                onHide={() => onHide({ shouldUpdate: false, close: true })}
              />
            </Grid>
          </Grid>
        </form>
      )}
      <NotificationSnackBar
        message={message}
        onHide={() => setNotificationMessage("")}
      />
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};
