import { ErrorModal } from "@dexteel/mesf-core";
import {
  Box,
  Chip,
  CircularProgress,
  Collapse,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { FieldError } from "react-hook-form";
import { ConfirmationDialogRaw } from "../../controls/modals/ConfirmationDialogRaw";
import {
  autocompleteOptionColor,
  useAutocompleteOptionStyles,
} from "../../utils/theme";
import { Roll } from "../models/Roll";
import { getReadyRolls } from "../repositories/FilterRollsRepository";
import { RollChannelStatus } from "./RollChannelStatus";
import { rollPositionCodes } from "./RollPositionSelector";

type Props = {
  label: string;
  value: number | null;
  onChange?: Function;
  fieldError?: FieldError | undefined;
  disabled?: boolean;
  filterRolls?: "T" | "B" | "A";
  onForceAssembly?: (force: boolean) => void;
  params?: {
    sectionId?: number | null | undefined;
    standId?: number | null | undefined;
  };
};

type useRollsProps = {
  sectionId?: number | null | undefined;
  standId?: number | null | undefined;
  onError: (error: Error) => void;
  onSuccess?: (data: Roll[]) => void;
};

export const useRolls = ({
  sectionId,
  standId,
  onError,
  onSuccess,
}: useRollsProps) => {
  return useQuery<Roll[], Error>({
    queryKey: ["rolls", sectionId, standId],
    queryFn: () =>
      getReadyRolls({
        SectionId: sectionId!,
        StandId: standId!,
        RollTypeCode: null,
      }),
    onError: onError,
    onSuccess: onSuccess,
  });
};

export const RollSelector = ({
  label,
  value,
  onChange = () => {},
  fieldError,
  disabled = false,
  filterRolls = "A",
  onForceAssembly = undefined,
  params = { sectionId: undefined, standId: undefined },
}: Props) => {
  const classes = useAutocompleteOptionStyles();

  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [selectedValue, setSelectedValue] = useState<Roll | null>(null);

  const { data: rolls, isLoading } = useRolls({
    sectionId: params.sectionId,
    standId: params.standId,
    onError: (error) => setError(error.message),
  });

  const selectedRoll = rolls?.find((roll) => roll.PartId === value) || null;

  const inUse = (props: Roll | null) => {
    if (props?.InUse && props?.PartId !== value) return true;
    return false;
  };

  const handleConfirmationClose = (result?: string) => {
    setMessage("");
    if (result === "OK") {
      onForceAssembly ? onForceAssembly(true) : null;
      onChange(selectedValue?.PartId ?? null);
      setSelectedValue(null);
      return;
    }
  };

  return (
    <>
      <Autocomplete
        id="clear-on-escape"
        clearOnEscape
        options={
          (filterRolls
            ? rolls?.filter(
                (roll) =>
                  roll.RollPosition === filterRolls || roll.RollPosition === "A"
              )
            : rolls) || []
        }
        getOptionLabel={(option) => option.PartName}
        renderOption={(props, option) => {
          return (
            <Box
              bgcolor={
                inUse(props) && onForceAssembly ? autocompleteOptionColor : ""
              }
              component="li"
              className={classes.optionBox}
            >
              <span>
                <Typography>{props.PartName}</Typography>
              </span>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  gap: "8px",
                  marginTop: 7,
                }}
              >
                {!params.sectionId && props?.Sections && (
                  <Chip
                    size="small"
                    variant="outlined"
                    color="secondary"
                    label={props?.Sections ?? "-"}
                  />
                )}
                {!params.standId && props?.Stands && (
                  <Chip
                    size="small"
                    variant="outlined"
                    color="primary"
                    label={props?.Stands ?? "-"}
                  />
                )}
                <Chip
                  size="small"
                  variant="outlined"
                  label={props?.Diameter ?? 0}
                />
                <Chip
                  size="small"
                  variant="outlined"
                  color="default"
                  label={
                    props?.RollPosition === "T"
                      ? "Top"
                      : props.RollPosition === "B"
                      ? "Bottom"
                      : "Any"
                  }
                />
              </div>
            </Box>
          );
        }}
        getOptionSelected={(option, value) => option.PartId === value.PartId}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            fullWidth
            size="small"
            error={!!fieldError}
            helperText={fieldError?.message}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    params.InputProps.endAdornment
                  )}
                </>
              ),
            }}
          />
        )}
        value={selectedRoll}
        onChange={(event, newValue) => {
          if (inUse(newValue) && onForceAssembly) {
            setMessage(
              `The roll ${newValue?.PartName} is already in use by another carrier, do you want to disassemble it?`
            );
            setSelectedValue(newValue);
          } else onChange(newValue?.PartId ?? null);
        }}
      />
      <Collapse in={!!selectedRoll} style={{ margin: 5 }}>
        <Typography variant="body2" color="textSecondary">
          {`${selectedRoll?.Sections ?? "-"}
          | ${selectedRoll?.Stands ?? "-"}
          | ${selectedRoll?.Diameter ?? "-"}
          | ${
            rollPositionCodes.find(
              (position) => position.Code === selectedRoll?.RollPosition
            )?.Name
          }
          `}
        </Typography>
        {selectedRoll?.RollTypeCode === "E" && (
          <RollChannelStatus channelsString={selectedRoll?.ChannelsStatus} />
        )}
      </Collapse>
      <ErrorModal
        error={error}
        onHide={() => setError("")}
        title="Error loading rolls"
      />
      <ConfirmationDialogRaw
        title="CONFIRMATION"
        message={message}
        open={message !== ""}
        onClose={handleConfirmationClose}
      />
    </>
  );
};
