import { ErrorModal } from "@dexteel/mesf-core";
import { Grid, TextField } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Carrier } from "../../../../../pages/assembly/models/Carrier";
import { assemblyStatusCodes } from "../../../../configuration/pages/parts/carriers/components/CarrierDetails";
import { Part } from "../../models/Part";
import { getCarriers } from "../../repositories/parts/CarrierRepository";

type useCarriersProps = {
  partId: number;
  onError: (error: Error) => void;
  onSuccess?: (data: Carrier[]) => void;
};

export const useCarriers = ({
  partId,
  onError,
  onSuccess,
}: useCarriersProps) => {
  return useQuery<Carrier[], Error>({
    queryKey: ["carriers", partId],
    queryFn: () => getCarriers(partId),
    onError: onError,
    onSuccess: onSuccess,
  });
};

type Props = {
  part: Part | null;
};

export const SpecificCarrierData = ({ part }: Props) => {
  const [error, setError] = useState("");
  const [carrier, setCarrier] = useState<Carrier>();

  const { data: carriers } = useCarriers({
    partId: part?.PartId!,
    onError: (error) => setError(error.message),
    onSuccess: (data: Carrier[]) => {
      setCarrier(data[0]);
    },
  });

  return (
    <>
      <Grid container spacing={1}>
        <Grid item md={6} sm={12} xs={12}>
          <TextField
            value={
              assemblyStatusCodes.find(
                (status) => status.Code === carrier?.AssemblyStatusCode
              )?.Name || ""
            }
            label="Assembly Status"
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <TextField
            value={carrier?.SectionName || ""}
            label="Section"
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <TextField
            value={carrier?.ScheduledLocationName || ""}
            label="Stand"
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <TextField
            value={carrier?.CassetteName || ""}
            label="Cassette"
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <TextField
            value={carrier?.TopRollName || ""}
            label="Top Roll"
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <TextField
            value={carrier?.BottomRollName || ""}
            label="Bottom Roll"
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <TextField
            value={carrier?.UMChockName || ""}
            label="Top Machine Chock"
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <TextField
            value={carrier?.LMChockName || ""}
            label="Bottom Machine Chock"
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <TextField
            value={carrier?.UOChockName || ""}
            label="Top Operator Chock"
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <TextField
            value={carrier?.LOChockName || ""}
            label="Bottom Operator Chock"
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <TextField
            value={carrier?.EntryGuideName || ""}
            label="Entry Guide"
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <TextField
            value={carrier?.ExitGuideName || ""}
            label="Delivery Guide"
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <TextField
            value={carrier?.Bait || ""}
            label="Bait"
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <TextField
            value={carrier?.WeighUp || ""}
            label="Weigh Up"
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};
