export interface Part {
    PartId: number | null;
	PartName: string;
	RFIDTag: string | null;
	LocationName: string | null;
	LocationId: number | null,
	Comments: string;
}

export const PART_INITIAL_VALUES: Part = {
	PartId: null,
	PartName: '',
	RFIDTag: '',
	LocationName: '',
	LocationId: null,
	Comments: ''
}